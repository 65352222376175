export class ServiceInvoiceLinesUpdateDto {
  InvoiceId: number;
  InvoiceLinesEdits: InvoiceLineUpdateDto[];
}

export class InvoiceLineUpdateDto {
  LineId: number;
  YourRef: string;
  MarkedWith: string;
  OrderDate: Date;
}
