import { CategoryDimensionType, MooringArticleProduct } from './';
import { MooringArticleMaterial } from './MooringArticleMaterial';

export class CreateMooringArticle {
  static ApiUrl = 'api/mooring-articles';
  Id?: number;
  CategoryId: number;
  TypeId: number;
  ProductTypeId?: number;
  SupplierId: number;
  WeightInAir?: number;
  WeightInWater?: number;
  PrimaryDimension: number;
  SecondaryDimension?: number;
  CriteriaForValidityCategoryId?: number;
  ArticleProducts?: {
    Id: number;
    MooringArticleId: number;
    ProductId: number;
  }[];
  MooringArticleMaterials?: MooringArticleMaterial[];
  MBL?: number;
  Inactive: boolean;
}

export class MooringArticle {
  static ApiUrl = 'api/mooring-articles';
  Id: number;
  CategoryId: number;
  CategoryName: string;
  TypeId: number;
  TypeName: string;
  ProductTypeId?: number;
  ProductTypeName: string;
  SupplierId: number;
  SupplierName: string;
  ArticleProducts?: MooringArticleProduct[];
  MBL?: number;
  WeightInAir?: number;
  WeightInWater?: number;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
  Inactive: boolean;
  CriteriaForValidityCategoryId?: number;
  MooringArticleMaterials?: MooringArticleMaterial[];
  HasCertificate: boolean;
  HasApprovedCertificate: boolean;
}

export class MooringArticleListItem {
  static ApiUrl = 'api/mooring-articles/items';
  Id: number;
  Category: string;
  Type: string;
  ProductType: string;
  ArticleNumbers: string[];
  Supplier: string;
  MBL: number;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
}
