export enum Type {
  DEFAULT = 0,
  CHAIN = 1,
  ANCHOR = 2,
  SHACKLES = 3,
  THIMBLE = 4,
  CLUTCHDISC = 5,
  RING = 6,
  BOLTS = 7,
  FLEXILINK = 8,
  BUOY = 9,
}

export class MooringArticleCategoryType {
  static ApiUrl = 'api/mooring-article-category-types';
  Id: number;
  Name: string;
  NameEn: string;
  NameEs: string;
  Type: number;
}
