import { Expose } from 'class-transformer';
import { MooringArticleCategoryType } from './MooringArticleCategoryType';

export enum CategoryDimensionType {
  Millimeter = 0,
  Centimeter = 1,
  Meter = 2,
  //
  Kilo = 3,
  Ton = 4,
  //
  Liter = 5,
}

export enum WeightUnitType {
  Kg_m = 0,
  Kg_pcs = 1,
}

export const WeightTypeMap = new Map<WeightUnitType, { label: string; value: WeightUnitType }>([
  [WeightUnitType.Kg_m, { label: 'kg/m', value: WeightUnitType.Kg_m }],
  [WeightUnitType.Kg_pcs, { label: 'general.kg_pcs', value: WeightUnitType.Kg_pcs }],
]);

export const weightUniOptions = () => {
  return Array.from(WeightTypeMap.values());
};

export const getArticleWeightType = (weightType: WeightUnitType) => {
  let str = ``;
  const type = WeightTypeMap.get(weightType);
  console.log(type, weightType);
  if (type) {
    str += type.label;
  }

  return str;
};

export const categoryDimensionTypeMap = new Map<CategoryDimensionType, { label: string; labelShort: string }>([
  [CategoryDimensionType.Millimeter, { label: 'general.millimeter', labelShort: 'mm' }],
  [CategoryDimensionType.Centimeter, { label: 'general.centimeter', labelShort: 'cm' }],
  [CategoryDimensionType.Meter, { label: 'general.meter', labelShort: 'm' }],
  [CategoryDimensionType.Kilo, { label: 'general.kilo', labelShort: 'kg' }],
  [CategoryDimensionType.Ton, { label: 'general.ton', labelShort: 'T' }],
  [CategoryDimensionType.Liter, { label: 'general.liter', labelShort: 'l' }],
]);

export const categoryDimensionTypes = [
  {
    value: CategoryDimensionType.Millimeter,
    label: 'general.millimeter',
    labelShort: 'mm',
  },
  {
    value: CategoryDimensionType.Centimeter,
    label: 'general.centimeter',
    labelShort: 'cm',
  },
  {
    value: CategoryDimensionType.Meter,
    label: 'general.meter',
    labelShort: 'm',
  },
  {
    value: CategoryDimensionType.Kilo,
    label: 'general.kilo',
    labelShort: 'kg',
  },
  {
    value: CategoryDimensionType.Ton,
    label: 'general.ton',
    labelShort: 'T',
  },
  {
    value: CategoryDimensionType.Liter,
    label: 'general.liter',
    labelShort: 'l',
  },
];

type DimenstionModel = {
  PrimaryDimension?: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
};

export const getArticleDimensionStringShort = (model: DimenstionModel) => {
  let str = ``;
  if (model.PrimaryDimension) {
    str += `${model.PrimaryDimension} ${categoryDimensionTypeMap.get(model.PrimaryDimensionType).labelShort} `;
  }
  if (model.SecondaryDimension && model.SecondaryDimensionType !== undefined) {
    str += `x ${model.SecondaryDimension} ${categoryDimensionTypeMap.get(model.SecondaryDimensionType).labelShort} `;
  }

  return str;
};

export class MooringArticleCategory {
  static ApiUrl = 'api/mooring-article-categories';
  Id: number;
  Name: string;
  NameEn: string;
  NameEs: string;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimensionType?: CategoryDimensionType;
  WeightUnitType: WeightUnitType;
  MooringArticleCategoryTypeId: number;
  MooringArticleCategoryType?: MooringArticleCategoryType;

  AquacomId?: number
}

@Expose()
export class AquacomComponentCategory{
  Id: number;
  Name: string;
  IndustryId: number;
}
