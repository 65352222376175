export module FileAttachmentModels {
  export class FileAttachment {  
    // this needs to be set based on what entity we are retrieving attachments for
    public static ApiUrl: string = '';

    Id: string;
    FileName: string;   
    Length: number;
    FileUrl: string;
  }
}
