import { CategoryDimensionType } from './MooringArticleCategory';
import { CertificateProducer } from './MooringSupplier';

export enum MooringCertificateReliabilityClass {
  II = 2,
  III = 3,
}
export class MooringCertificate {
  static ApiUrl = 'api/mooring-certificates';
  Id: number;

  ArticleProductId: number;
  ArticleProduct: {
    ProductName: string;
    ArticleNumber: string;
    ProductId: number;
    MooringArticleId: number;
  };

  ApprovedBy?: string;
  ApprovedById?: string;
  ApprovedAt?: Date;

  SupplierId: number;
  SupplierName: string;

  BatchNumber: string;
  BatchNumberTo: string;

  InternalOrderNumber?: string;

  DateOfGoodsReceipt?: Date;

  Quantity?: number;

  Inactive: boolean;

  MooringStationId: number;

  TemplateId?: number;

  ReliabilityClass: MooringCertificateReliabilityClass;

  ProducerDocumentationAttachmentId?: number;
  CertificateAttachmentId?: number;
}

export class CreateUpdateMooringCertificate {
  Id?: number;

  SupplierId: number;
  BatchNumber: string;
  BatchNumberTo: string;

  InternalOrderNumber?: string;

  DateOfGoodsReceipt?: Date;

  Quantity?: number;
  ReliabilityClass: MooringCertificateReliabilityClass;

  TemplateId?: number;
  ProducerDocumentationAttachment?: File;
  CertificateAttachment?: File;
}

export class MooringCertificateListItem {
  Id: number;

  ApprovedAt?: Date;

  ArticleProductId: number;

  SupplierName: string;
  SupplierId: number;

  CertificateProducer: CertificateProducer;

  CategoryName: string;
  CategoryId: number;

  TypeName: string;
  TypeId: number;

  ProductTypeName?: string;
  ProductTypeId?: number;

  ArticleNo: string;
  ProductName: string;

  BatchNumber: string;
  BatchNumberTo: string;

  InternalOrderNumber?: string;

  DateOfGoodsReceipt?: Date;
  ReliabilityClass: MooringCertificateReliabilityClass;

  Quantity?: number;

  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;

  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
}

export class MooringFrameCertificateListItem {
  Id: number;

  ApprovedAt?: Date;
  ArticleProductId: number;
  OrderId: number;
  CertificateNumber: string;
  CustomerName?: string;
  CustomerId?: number;
  LocalityName?: string;
  LocalityId?: number;
}

export class CheckDuplicateMooringCertificate {
  ArticleProductId: number;
  BatchNumber: string;
}
